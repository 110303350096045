.ConnectData {
  background-color: #f9fafb;

}

.ConnectData .overlap-wrapper {
  background-color: #f9fafb;

}

.ConnectData .overlap {

}

.ConnectData .overlap-group {

  left: 0;
  top: 0;

}

.ConnectData .rectangle {
  background-color: #ffffff;
  height: 60px;
  left: 0;
  top: 0;
  width: 1688px;
}

.ConnectData .element {
  height: 1px;
  left: 240px;
  object-fit: cover;
  top: 60px;
  width: 1448px;
}

.ConnectData .text-wrapper {
  color: var(--greyscale-colorgrey-7);
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 32px;
  left: 264px;
  letter-spacing: -0.34px;
  line-height: 32px;
  top: 13px;
  white-space: nowrap;
}

.ConnectData .div {
  align-items: center;
  background-color: var(--greyscale-colorgrey-8);
  border-radius: 6px;
  display: inline-flex;
  gap: 6px;
  justify-content: flex-end;
  left: 1554px;
  overflow: hidden;
  padding: 6px 8px;
  top: 10px;
}

.ConnectData .group {
  height: 28px;
  width: 30px;
}

.ConnectData .div-wrapper {
  background-color: #83b8ff;
  border-radius: 14px;
  height: 28px;
  width: 28px;
}

.ConnectData .text-wrapper-2 {
  color: var(--greyscale-colorgrey-0);
  font-family: var(--pretendard-bold-pretendard-12pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-12pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-12pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-12pt-bold-font-weight);
  left: 9px;
  letter-spacing: var(--pretendard-bold-pretendard-12pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-12pt-bold-line-height);
  top: 4px;
  white-space: nowrap;
}

.ConnectData .text-wrapper-3 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 22px;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.ConnectData .rectangle-2 {
  background-color: #33363b;
  height: 969px;
  left: 0;
  top: 0;
  width: 240px;
}

.ConnectData .headless-logo {
  height: 24px;
  left: 24px;
  top: 17px;
  width: 140px;
}

.ConnectData .img {
  height: 1px;
  left: 0;
  object-fit: cover;
  top: 58px;
  width: 240px;
}

.ConnectData .div-2 {
  align-items: center;
  background-color: #faecf1;
  border-radius: 20px;
  display: flex;
  gap: 6px;
  justify-content: center;
  left: 1385px;
  overflow: hidden;
  padding: 3px 8px;
  top: 16px;
  width: 157px;
}

.ConnectData .warning-tooltip-icon {
  height: 16px;
  width: 16px;
}

.ConnectData .text-wrapper-4 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-bold-pretendard-14pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-14pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-14pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-14pt-bold-font-weight);
  letter-spacing: var(--pretendard-bold-pretendard-14pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-14pt-bold-line-height);
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.ConnectData .overlap-2 {
  height: 758px;
  width: 922px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #DADADA;
  background-color: #ffffff;
}

.ConnectData .overlap-group-wrapper {
  height: 40px;
  left: 1px;
  top: 0;
  width: 918px;
}

.ConnectData .overlap-3 {
  height: 40px;
}

.ConnectData .element-2 {
  height: 40px;
  left: 0;
  top: 0;
  width: 460px;
}

.ConnectData .element-3 {
  height: 40px;
  left: 459px;
  top: 0;
  width: 460px;
}

.ConnectData .element-4 {
  height: 2px;
  left: 0;
  top: 38px;
  width: 460px;
}

.ConnectData .group-2 {
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EEEEFF;
  gap: 5px;

  border-bottom: 1px solid #4046E3;
}

.ConnectData .group-5 {
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F2F2F2;
  gap: 5px;
}

.ConnectData .element-5 {
  height: 20px;

  width: 20px;
}

.ConnectData .text-wrapper-5 {
  color: var(--greyscale-colorgrey-7);
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  left: 30px;
  letter-spacing: -0.24px;
  line-height: 22px;
  top: 0;
  white-space: nowrap;
}

.ConnectData .group-3 {
  height: 22px;
  left: 183px;
  top: 9px;
  width: 95px;
}

.ConnectData .button-4 {
  color: #f9fafb;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

  height: 40px;
  width: 72px;
  background: var(--brand-colormainblue-0);
  border-radius: 10px;
}

.ConnectData .rectangle-3 {

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  grid-gap: 0px;
}

.ConnectData .rectangle-4 {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  place-items: center;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 30px;
  padding-bottom: 30px;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-2);
  border-radius: 6px;
  height: 220px;
  width: 259px;
}

.ConnectData .rectangle-5 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-2);
  border-radius: 6px;
  height: 169px;
  left: 38px;
  top: 391px;
  width: 259px;
}

.ConnectData .rectangle-6 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-2);
  border-radius: 6px;
  height: 169px;
  left: 317px;
  top: 206px;
  width: 259px;
}

.ConnectData .rectangle-7 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-2);
  border-radius: 6px;
  height: 169px;
  left: 596px;
  top: 206px;
  width: 259px;
}

.ConnectData .coupang-logo {
  height: 29px;
  left: 383px;
  object-fit: cover;
  top: 246px;
  width: 126px;
}

.ConnectData .text-wrapper-6 {
  color: var(--color-neutral-foreground-2-rest);
  font-family: var(--pretendard-bold-pretendard-16pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-16pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-16pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-16pt-bold-font-weight);
  left: 38px;
  letter-spacing: var(--pretendard-bold-pretendard-16pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-16pt-bold-line-height);
  top: 79px;
  white-space: nowrap;
}

.ConnectData .element-6 {
  height: 26px;
  left: 654px;
  object-fit: cover;
  top: 248px;
  width: 143px;
}

.ConnectData .element-7 {
  height: 38px;
  left: 88px;
  object-fit: cover;
  top: 242px;
  width: 38px;
}

.ConnectData .text-wrapper-7 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-bold-pretendard-20pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-20pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-20pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-20pt-bold-font-weight);
  height: 32px;
  left: 139px;
  letter-spacing: var(--pretendard-bold-pretendard-20pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-20pt-bold-line-height);
  top: 243px;
  white-space: nowrap;
}

.ConnectData .text-wrapper-8 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-bold-pretendard-20pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-20pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-20pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-20pt-bold-font-weight);
  height: 32px;
  left: 95px;
  letter-spacing: var(--pretendard-bold-pretendard-20pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-20pt-bold-line-height);
  top: 428px;
  white-space: nowrap;
}

.ConnectData .button-instance {
  background-color: var(--brand-colormainblue-l1) !important;
  border: unset !important;
  border-color: unset !important;
  display: flex !important;

  width: 215px !important;
}

.ConnectData .design-component-instance-node {
  background-color: var(--brand-colormainblue-l1) !important;
  border: unset !important;
  border-color: unset !important;
  display: flex !important;
  left: 339px !important;
  top: 301px !important;
  width: 215px !important;
}

.ConnectData .button-2 {
  background-color: var(--brand-colormainblue-l1) !important;
  border: unset !important;
  border-color: unset !important;
  display: flex !important;
  left: 60px !important;
  top: 301px !important;
  width: 215px !important;
}

.ConnectData .button-3 {
  background-color: var(--brand-colormainblue-l1) !important;
  border: unset !important;
  border-color: unset !important;
  display: flex !important;
  left: 60px !important;
  top: 486px !important;
  width: 215px !important;
}

.ConnectData .text-wrapper-9 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-16pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-16pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-16pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-16pt-medium-font-weight);
  left: 178px;
  letter-spacing: var(--pretendard-medium-pretendard-16pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-16pt-medium-line-height);
  top: 79px;
  white-space: nowrap;
}

.ConnectData .p {
  color: var(--greyscale-colorgrey-5);
  font-family: var(--pretendard-medium-pretendard-16pt-medium-font-family);
  font-size: var(var(--pretendard-medium-pretendard-12pt-medium-font-family));
  font-style: var(--pretendard-medium-pretendard-16pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-16pt-medium-font-weight);
  left: 38px;
  letter-spacing: var(--pretendard-medium-pretendard-16pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-16pt-medium-line-height);
  top: 116px;
  width: 817px;
}

.ConnectData .group-4 {
  height: 60px;
  left: 0;
  top: 648px;
  width: 922px;
}

.ConnectData .element-8 {
  height: 40px;
  left: 3726px;
  top: 837px;
  width: 872px;
}

.ConnectData .overlap-4 {
  height: 60px;
  left: 0;
  top: 0;
  width: 920px;
}

.ConnectData .element-9 {
  height: 60px;
  left: 0;
  top: 0;
  width: 916px;
}

.ConnectData .rectangle-8 {
  background-color: #ffffff;

  display: flex;
  place-items: center;
  justify-content: end;
  border-color: #dadada;
  height: 60px;
  width: 100%;
  padding-right: 28px;
}

.ConnectData .element-10 {
  height: 40px;
  left: 820px;
  top: 10px;
  width: 72px;
}

.ConnectData .text-wrapper-10 {
  color: #747474;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 844px;
  letter-spacing: 0;
  line-height: normal;
  top: 21px;
}

.ConnectData .text-wrapper-11 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-bold-pretendard-26pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-26pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-26pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-26pt-bold-font-weight);

  padding: 20px;
  padding-left: 25px;
  letter-spacing: var(--pretendard-bold-pretendard-26pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-26pt-bold-line-height);

  white-space: nowrap;
}


.ConnectData2 {

  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.ConnectData2 .overlap-wrapper {

  height: 969px;
  width: 1688px;
}

.ConnectData2 .overlap {
  height: 969px;

}

.ConnectData2 .overlap-group {
  height: 969px;
  left: 0;

  top: 0;
  width: 1688px;
}

.ConnectData2 .rectangle {
  background-color: #ffffff;
  height: 60px;
  left: 0;

  top: 0;
  width: 1688px;
}

.ConnectData2 .element {
  height: 1px;
  left: 240px;
  object-fit: cover;

  top: 60px;
  width: 1448px;
}

.ConnectData2 .text-wrapper {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-bold-pretendard-20pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-20pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-20pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-20pt-bold-font-weight);
  height: 32px;
  left: 264px;
  letter-spacing: var(--pretendard-bold-pretendard-20pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-20pt-bold-line-height);

  top: 13px;
  white-space: nowrap;
}

.ConnectData2 .div {
  align-items: center;
  background-color: var(--greyscale-colorgrey-8);
  border-radius: 6px;
  display: inline-flex;
  gap: 6px;
  justify-content: flex-end;
  left: 1554px;
  overflow: hidden;
  padding: 6px 8px;

  top: 10px;
}

.ConnectData2 .group {
  height: 28px;

  width: 30px;
}

.ConnectData2 .div-wrapper {
  background-color: #83b8ff;
  border-radius: 14px;
  height: 28px;

  width: 28px;
}

.ConnectData2 .text-wrapper-2 {
  color: var(--greyscale-colorgrey-0);
  font-family: var(--pretendard-bold-pretendard-12pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-12pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-12pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-12pt-bold-font-weight);
  left: 9px;
  letter-spacing: var(--pretendard-bold-pretendard-12pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-12pt-bold-line-height);

  top: 4px;
  white-space: nowrap;
}

.ConnectData2 .text-wrapper-3 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 22px;

  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.ConnectData2 .rectangle-2 {
  background-color: #33363b;
  height: 969px;
  left: 0;

  top: 0;
  width: 240px;
}

.ConnectData2 .headless-logo {
  height: 24px;
  left: 24px;

  top: 17px;
  width: 140px;
}

.ConnectData2 .img {
  height: 1px;
  left: 0;
  object-fit: cover;

  top: 58px;
  width: 240px;
}

.ConnectData2 .div-2 {
  align-items: center;
  background-color: #faecf1;
  border-radius: 20px;
  display: flex;
  gap: 6px;
  justify-content: center;
  left: 1385px;
  overflow: hidden;
  padding: 3px 8px;

  top: 16px;
  width: 157px;
}

.ConnectData2 .warning-tooltip-icon {
  height: 16px;

  width: 16px;
}

.ConnectData2 .text-wrapper-4 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-bold-pretendard-14pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-14pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-14pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-14pt-bold-font-weight);
  letter-spacing: var(--pretendard-bold-pretendard-14pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-14pt-bold-line-height);
  margin-top: -1px;

  white-space: nowrap;
  width: fit-content;
}

.ConnectData2 .overlap-2 {
  height: 42px;
  left: 517px;

  top: 107px;
  width: 166px;
}

.ConnectData2 .text-wrapper-5 {
  color: #e53073;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 59px;
  letter-spacing: 0;
  line-height: normal;

  top: 7px;
  width: 9px;
}

.ConnectData2 .text-wrapper-6 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-bold-pretendard-26pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-26pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-26pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-26pt-bold-font-weight);
  left: 0;
  letter-spacing: var(--pretendard-bold-pretendard-26pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-26pt-bold-line-height);

  top: 0;
  white-space: nowrap;
}

.ConnectData2 .overlap-3 {
  height: 669px;
  left: 493px;

  top: 205px;
  width: 924px;
}

.ConnectData2 .rectangle-3 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dadada;
  height: 669px;
  left: 4px;

  top: 0;
  width: 920px;
}

.ConnectData2 .text-wrapper-7 {
  color: var(--color-neutral-foreground-2-rest);
  font-family: var(--pretendard-semibold-pretendard-16pt-semibold-font-family);
  font-size: var(--pretendard-semibold-pretendard-16pt-semibold-font-size);
  font-style: var(--pretendard-semibold-pretendard-16pt-semibold-font-style);
  font-weight: var(--pretendard-semibold-pretendard-16pt-semibold-font-weight);
  left: 42px;
  letter-spacing: var(--pretendard-semibold-pretendard-16pt-semibold-letter-spacing);
  line-height: var(--pretendard-semibold-pretendard-16pt-semibold-line-height);

  top: 40px;
  white-space: nowrap;
}

.ConnectData2 .button-instance {
  background-color: var(--greyscale-colorgrey-1) !important;
  border: unset !important;
  border-color: unset !important;
  display: flex !important;
  left: 42px !important;

  top: 160px !important;
  width: 215px !important;
}

.ConnectData2 .design-component-instance-node {
  color: #474a4e !important;
}

.ConnectData2 .p {
  color: var(--greyscale-colorgrey-5);
  font-family: var(--pretendard-medium-pretendard-16pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-16pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-16pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-16pt-medium-font-weight);
  left: 42px;
  letter-spacing: var(--pretendard-medium-pretendard-16pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-16pt-medium-line-height);

  top: 77px;
  width: 817px;
}

.ConnectData2 .text-wrapper-8 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-medium-pretendard-16pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-16pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-16pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-16pt-medium-font-weight);
  left: 179px;
  letter-spacing: var(--pretendard-medium-pretendard-16pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-16pt-medium-line-height);

  top: 40px;
  white-space: nowrap;
}

.ConnectData2 .group-2 {
  height: 60px;
  left: 0;

  top: 607px;
  width: 924px;
}

.ConnectData2 .element-2 {
  height: 40px;
  left: 3726px;

  top: -497px;
  width: 872px;
}

.ConnectData2 .overlap-4 {
  height: 60px;
  left: 0;

  top: 0;
  width: 920px;
}

.ConnectData2 .element-3 {
  height: 60px;
  left: 0;

  top: 0;
  width: 916px;
}

.ConnectData2 .rectangle-4 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dadada;
  height: 60px;
  left: 0;

  top: 0;
  width: 920px;
}

.ConnectData2 .element-4 {
  height: 40px;
  left: 801px;

  top: 10px;
  width: 91px;
}

.ConnectData2 .text-wrapper-9 {
  color: #ffffff;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 820px;
  letter-spacing: 0;
  line-height: normal;

  top: 21px;
}

.ConnectData2 .element-5 {
  height: 40px;
  left: 715px;

  top: 10px;
  width: 72px;
}

.ConnectData2 .text-wrapper-10 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;

  top: 21px;
}

.ConnectData2 .group-3 {
  height: 40px;
  left: 497px;

  top: 165px;
  width: 924px;
}

.ConnectData2 .overlap-5 {
  background-size: 100% 100%;
  height: 40px;
  left: 0;

  top: 0;
  width: 460px;
}

.ConnectData2 .element-6 {
  height: 20px;
  left: 182px;

  top: 10px;
  width: 20px;
}

.ConnectData2 .text-wrapper-11 {
  color: var(--greyscale-colorgrey-7);
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  left: 212px;
  letter-spacing: -0.24px;
  line-height: 22px;

  top: 9px;
  white-space: nowrap;
}

.ConnectData2 .overlap-6 {
  height: 40px;
  left: 460px;

  top: 0;
  width: 460px;
}

.ConnectData2 .element-7 {
  height: 40px;
  left: 0;

  top: 0;
  width: 460px;
}

.ConnectData2 .element-8 {
  height: 2px;
  left: 0;

  top: 38px;
  width: 460px;
}

.ConnectData2 .element-9 {
  height: 20px;
  left: 178px;

  top: 10px;
  width: 20px;
}

.ConnectData2 .text-wrapper-12 {
  color: var(--greyscale-colorgrey-7);
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  left: 208px;
  letter-spacing: -0.24px;
  line-height: 22px;

  top: 9px;
  white-space: nowrap;
}

.box {
  height: 703px;
  width: 664px;
  background-color: #ffffff;
  border: #D8DDE3 solid ;
  border-radius: 5px;
  padding: 20px;
}

.box .group {
  height: 100px;
  width: 100%;
  background: #F2F4F7;
  border-radius: 5px;
  border: 1px solid #D8DDE3;
}

.box .overlap {

  background-size: 100% 100%;
  height: 715px;
  left: -6px;

  top: -4px;
}

.box .overlap-group {

  background-size: 100% 100%;
  height: 121px;
  left: 26px;

  top: 82px;
  width: 624px;
}

.box .div-wrapper {

  background-size: 100% 100%;
  height: 40px;
  left: 24px;

  top: 52px;
  width: 478px;
}

.box .text-wrapper {
  color: #747474;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;

  top: 12px;
}

.box .div {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;

  top: 23px;
}

.box .overlap-2 {

  background-size: 100% 100%;
  height: 40px;
  left: 510px;

  top: 52px;
  width: 94px;
}

.box .text-wrapper-2 {
  color: #747474;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 21px;
  letter-spacing: 0;
  line-height: normal;

  top: 11px;
}

.box .text-wrapper-3 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;

  top: 24px;
  white-space: nowrap;
}

.box .element {
  height: 1px;
  left: 26px;
  object-fit: cover;

  top: 64px;
  width: 624px;
}

.box .p {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 47px;
  letter-spacing: 0;
  line-height: normal;

  top: 249px;
}

.box .span {
  font-weight: 500;
}

.box .text-wrapper-4 {
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
}

.box .text-wrapper-5 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 47px;
  letter-spacing: 0;
  line-height: normal;

  top: 227px;
}

.box .overlap-group-wrapper {
  height: 60px;
  left: 6px;

  top: 647px;
  width: 668px;
}

.box .overlap-group-2 {

  background-size: 100% 100%;
  height: 60px;

  width: 664px;
}

.box .overlap-3 {

  background-size: 100% 100%;
  height: 36px;
  left: 530px;

  top: 12px;
  width: 96px;
}

.box .text-wrapper-6 {
  color: #f9fafb;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;

  top: 10px;
}

.box .overlap-4 {

  background-size: 100% 100%;
  height: 36px;
  left: 450px;

  top: 12px;
  width: 72px;
}

.box .text-wrapper-7 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;

  top: 10px;
}

.box .img {
  height: 305px;
  left: 47px;
  object-fit: cover;

  top: 296px;
  width: 581px;
}