.BrandValue {
  background-color: #f9fafb;

  
}

.BrandValue .div {
  background-color: #f9fafb;

}

.BrandValue .overlap {
  height: 1030px;

  width: 1542px;
}

.BrandValue .w-arrow-right-icon {
  height: 8px;

  width: 16px;
}

.BrandValue .group {
  height: 60px;

  width: 1542px;
}

.BrandValue .element {
  height: 60px;

  width: 1536px;
}

.BrandValue .img {
  height: 1px;
  object-fit: cover;

  width: 1296px;
}

.BrandValue .element-2 {
  height: 40px;

  width: 207px;
}

.BrandValue .element-3 {
  height: 28px;

  width: 28px;
}

.BrandValue .text-wrapper {
  color: #ffffff;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  width: 1094px;
  white-space: nowrap;
}

.BrandValue .text-wrapper-2 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

}

.BrandValue .text-wrapper-3 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
}

.BrandValue .element-4 {
  height: 40px;

  width: 1248px;
}

.BrandValue .element-5 {
  height: 1030px;

  width: 240px;
}

.BrandValue .overlap-group {
  background-size: 100% 100%;
  height: 114px;

  width: 1094px;
}

.BrandValue .text-wrapper-4 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
}

.BrandValue .element-6 {
  height: 114px;

  width: 1px;
}

.BrandValue .element-7 {
  height: 114px;

  width: 1px;
}

.BrandValue .text-wrapper-5 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
}

.BrandValue .element-8 {
  color: #10a544;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

  text-align: right;
}

.BrandValue .element-9 {
  color: #474a4e;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

  text-align: right;
}

.BrandValue .element-10 {
  color: #474a4e;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

  text-align: right;
}

.BrandValue .text-wrapper-6 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
}

.BrandValue .text-wrapper-7 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
}

.BrandValue .text-wrapper-8 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
}

.BrandValue .text-wrapper-9 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
}

.BrandValue .group-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #bcc2c9;
  border-radius: 6px;
  height: 40px;

  width: 135px;
}

.BrandValue .group-2 {
  height: 17px;

  width: 100px;
}

.BrandValue .text-wrapper-10 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

}

.BrandValue .copy-icon {
  height: 16px;

  width: 16px;
}

.BrandValue .overlap-2 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 169px;

  width: 442px;
}

.BrandValue .rectangle {
  border: 1px solid;
  border-color: #d8dde3;
  border-radius: 6px;
  height: 169px;

  width: 442px;
}

.BrandValue .text-wrapper-11 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
}

.BrandValue .element-11 {
  color: #474a4e;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

}

.BrandValue .rectangle-2 {
  height: 44px;

  width: 442px;
}

.BrandValue .rectangle-3 {
  height: 44px;

  width: 442px;
}

.BrandValue .arrow-drop-down-icon {
  height: 8px;

  width: 8px;
}

.BrandValue .arrow-drop-down-icon-2 {
  height: 8px;

  width: 8px;
}

.BrandValue .element-12 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

}

.BrandValue .element-13 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

}

.BrandValue .element-14 {
  height: 88px;

  width: 1px;
}

.BrandValue .text-wrapper-12 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

}

.BrandValue .text-wrapper-13 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

}

.BrandValue .rectangle-4 {
  height: 88px;

  width: 442px;
}

.BrandValue .overlap-3 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 169px;

  width: 442px;
}

.BrandValue .overlap-4 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 169px;

  width: 442px;
}

.BrandValue .overlap-5 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 169px;

  width: 442px;
}

.BrandValue .div-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d8dde3;
  border-radius: 6px;
  height: 56px;

  width: 1094px;
}

.BrandValue .text-wrapper-14 {
  color: #7b828b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
}

.BrandValue .overlap-wrapper {
  height: 127px;

  width: 205px;
}

.BrandValue .overlap-6 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d8dde3;
  border-radius: 6px;
  box-shadow: 0px 2px 6px #33363b29;
  height: 127px;

  width: 199px;
}

.BrandValue .text-wrapper-15 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

}

.BrandValue .text-wrapper-16 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

}

.BrandValue .text-wrapper-17 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

}

.BrandValue .element-15 {
  height: 21px;

  width: 216px;
}
