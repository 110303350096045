.FutureClientSegmentation {

  background-color: #f9fafb;
  
}

.FutureClientSegmentation .overlap-wrapper {
  background-color: #f9fafb;
 
  overflow: hidden;

}

.FutureClientSegmentation .overlap {
  height: 3425px;
  width: 1997px;
}

.FutureClientSegmentation .overlap-group {
  height: 3425px;
  left: 0;
  top: 0;
  width: 1536px;
}

.FutureClientSegmentation .group {
  height: 60px;
  left: 0;
  top: 0;
  width: 1536px;
}

.FutureClientSegmentation .element {
  height: 3425px;
  left: 0;
  top: 0;
  width: 240px;
}

.FutureClientSegmentation .text-wrapper {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;
  top: 93px;
  white-space: nowrap;
}

.FutureClientSegmentation .group-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #bcc2c9;
  border-radius: 6px;
  height: 40px;
  left: 1223px;
  top: 85px;
  width: 135px;
}

.FutureClientSegmentation .div {
  height: 17px;
  left: 18px;
  top: 11px;
  width: 100px;
}

.FutureClientSegmentation .text-wrapper-2 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  top: 0;
}

.FutureClientSegmentation .copy-icon {
  height: 16px;
  left: 0;
  top: 1px;
  width: 16px;
}

.FutureClientSegmentation .text-wrapper-3 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;
  top: 238px;
  white-space: nowrap;
}

.FutureClientSegmentation .text-wrapper-4 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;
  top: 461px;
  white-space: nowrap;
}

.FutureClientSegmentation .text-wrapper-5 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;
  top: 648px;
  white-space: nowrap;
}

.FutureClientSegmentation .text-wrapper-6 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;
  top: 1279px;
  white-space: nowrap;
}

.FutureClientSegmentation .text-wrapper-7 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;
  top: 2010px;
  white-space: nowrap;
}

.FutureClientSegmentation .overlap-2 {
  border-radius: 6px;
  height: 88px;
  left: 264px;
  top: 503px;
  width: 522px;
}

.FutureClientSegmentation .rectangle {
  height: 44px;
  left: 0;
  top: 0;
  width: 522px;
}

.FutureClientSegmentation .img {
  height: 44px;
  left: 0;
  top: 44px;
  width: 522px;
}

.FutureClientSegmentation .element-2 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  top: 54px;
}

.FutureClientSegmentation .element-3 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 194px;
  letter-spacing: 0;
  line-height: normal;
  top: 54px;
}

.FutureClientSegmentation .element-4 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 368px;
  letter-spacing: 0;
  line-height: normal;
  top: 54px;
}

.FutureClientSegmentation .element-5 {
  height: 88px;
  left: 174px;
  top: 0;
  width: 1px;
}

.FutureClientSegmentation .element-6 {
  height: 88px;
  left: 348px;
  top: 0;
  width: 1px;
}

.FutureClientSegmentation .text-wrapper-8 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  top: 12px;
}

.FutureClientSegmentation .text-wrapper-9 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 194px;
  letter-spacing: 0;
  line-height: normal;
  top: 12px;
}

.FutureClientSegmentation .text-wrapper-10 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 368px;
  letter-spacing: 0;
  line-height: normal;
  top: 12px;
}

.FutureClientSegmentation .rectangle-2 {
  border: 1px solid;
  border-color: #d8dde3;
  border-radius: 6px;
  height: 88px;
  left: 0;
  top: 0;
  width: 522px;
}

.FutureClientSegmentation .overlap-3 {
  border-radius: 6px;
  height: 88px;
  left: 264px;
  top: 2062px;
  width: 522px;
}

.FutureClientSegmentation .overlap-4 {
  background-size: 100% 100%;
  height: 114px;
  left: 264px;
  top: 288px;
  width: 1094px;
}

.FutureClientSegmentation .text-wrapper-11 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 384px;
  letter-spacing: 0;
  line-height: normal;
  top: 22px;
  white-space: nowrap;
}

.FutureClientSegmentation .element-7 {
  height: 114px;
  left: 365px;
  top: 0;
  width: 1px;
}

.FutureClientSegmentation .element-8 {
  height: 114px;
  left: 731px;
  top: 0;
  width: 1px;
}

.FutureClientSegmentation .text-wrapper-12 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 748px;
  letter-spacing: 0;
  line-height: normal;
  top: 22px;
  white-space: nowrap;
}

.FutureClientSegmentation .element-9 {
  color: #474a4e;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  left: 656px;
  letter-spacing: 0;
  line-height: normal;
  text-align: right;
  top: 58px;
}

.FutureClientSegmentation .element-10 {
  color: #474a4e;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  left: 976px;
  letter-spacing: 0;
  line-height: normal;
  text-align: right;
  top: 58px;
}

.FutureClientSegmentation .element-11 {
  color: #474a4e;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  left: 269px;
  letter-spacing: 0;
  line-height: normal;
  text-align: right;
  top: 58px;
}

.FutureClientSegmentation .text-wrapper-13 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 19px;
  letter-spacing: 0;
  line-height: normal;
  top: 22px;
  white-space: nowrap;
}

.FutureClientSegmentation .overlap-5 {
  height: 618px;
  left: 264px;
  top: 592px;
  width: 1733px;
}

.FutureClientSegmentation .vector {
  height: 519px;
  left: 0;
  top: 99px;
  width: 1093px;
}

.FutureClientSegmentation .vector-2 {
  height: 520px;
  left: 0;
  top: 98px;
  width: 1093px;
}

.FutureClientSegmentation .element-12 {
  height: 500px;
  left: 676px;
  top: 0;
  width: 1057px;
}

.FutureClientSegmentation .text-wrapper-14 {
  color: #33363b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 1013px;
  letter-spacing: 0;
  line-height: normal;
  top: 542px;
  white-space: nowrap;
}

.FutureClientSegmentation .text-wrapper-15 {
  color: #33363b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 31px;
  letter-spacing: 0;
  line-height: normal;
  top: 122px;
  white-space: nowrap;
}

.FutureClientSegmentation .element-13 {
  height: 1px;
  left: 75px;
  object-fit: cover;
  top: 398px;
  width: 955px;
}

.FutureClientSegmentation .element-14 {
  height: 1px;
  left: 75px;
  object-fit: cover;
  top: 468px;
  width: 955px;
}

.FutureClientSegmentation .element-15 {
  height: 1px;
  left: 75px;
  object-fit: cover;
  top: 328px;
  width: 955px;
}

.FutureClientSegmentation .element-16 {
  height: 1px;
  left: 75px;
  object-fit: cover;
  top: 258px;
  width: 955px;
}

.FutureClientSegmentation .element-17 {
  height: 1px;
  left: 75px;
  object-fit: cover;
  top: 188px;
  width: 955px;
}

.FutureClientSegmentation .element-18 {
  height: 1px;
  left: 75px;
  object-fit: cover;
  top: 538px;
  width: 955px;
}

.FutureClientSegmentation .element-19 {
  color: #7b828b;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 54px;
  letter-spacing: 0;
  line-height: normal;
  top: 528px;
}

.FutureClientSegmentation .element-20 {
  color: #7b828b;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  top: 458px;
}

.FutureClientSegmentation .text-wrapper-16 {
  color: #7b828b;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 29px;
  letter-spacing: 0;
  line-height: normal;
  top: 388px;
}

.FutureClientSegmentation .element-21 {
  color: #7b828b;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  top: 318px;
}

.FutureClientSegmentation .text-wrapper-17 {
  color: #7b828b;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  top: 248px;
}

.FutureClientSegmentation .text-wrapper-18 {
  color: #7b828b;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  top: 178px;
}

.FutureClientSegmentation .element-22 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 235px;
  letter-spacing: 0;
  line-height: normal;
  top: 553px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-23 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 342px;
  letter-spacing: 0;
  line-height: normal;
  top: 551px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-24 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 452px;
  letter-spacing: 0;
  line-height: normal;
  top: 551px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-25 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 562px;
  letter-spacing: 0;
  line-height: normal;
  top: 551px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-26 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 673px;
  letter-spacing: 0;
  line-height: normal;
  top: 551px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-27 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 783px;
  letter-spacing: 0;
  line-height: normal;
  top: 551px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-28 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 894px;
  letter-spacing: 0;
  line-height: normal;
  top: 552px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-29 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 158px;
  letter-spacing: 0;
  line-height: normal;
  top: 541px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-30 {
  height: 173px;
  left: 163px;
  top: 365px;
  width: 4px;
}

.FutureClientSegmentation .element-31 {
  height: 173px;
  left: 179px;
  top: 365px;
  width: 4px;
}

.FutureClientSegmentation .element-32 {
  height: 263px;
  left: 215px;
  top: 275px;
  width: 4px;
}

.FutureClientSegmentation .element-33 {
  height: 173px;
  left: 195px;
  top: 365px;
  width: 4px;
}

.FutureClientSegmentation .element-34 {
  height: 336px;
  left: 167px;
  top: 202px;
  width: 4px;
}

.FutureClientSegmentation .element-35 {
  height: 336px;
  left: 183px;
  top: 202px;
  width: 4px;
}

.FutureClientSegmentation .element-36 {
  height: 336px;
  left: 199px;
  top: 202px;
  width: 4px;
}

.FutureClientSegmentation .element-37 {
  height: 342px;
  left: 171px;
  top: 196px;
  width: 4px;
}

.FutureClientSegmentation .element-38 {
  height: 342px;
  left: 187px;
  top: 196px;
  width: 4px;
}

.FutureClientSegmentation .element-39 {
  height: 342px;
  left: 203px;
  top: 196px;
  width: 4px;
}

.FutureClientSegmentation .element-40 {
  height: 342px;
  left: 211px;
  top: 196px;
  width: 4px;
}

.FutureClientSegmentation .element-41 {
  height: 266px;
  left: 175px;
  top: 272px;
  width: 4px;
}

.FutureClientSegmentation .element-42 {
  height: 266px;
  left: 191px;
  top: 272px;
  width: 4px;
}

.FutureClientSegmentation .element-43 {
  height: 266px;
  left: 207px;
  top: 272px;
  width: 4px;
}

.FutureClientSegmentation .overlap-6 {
  height: 627px;
  left: 264px;
  top: 2077px;
  width: 1733px;
}

.FutureClientSegmentation .vector-3 {
  height: 528px;
  left: 0;
  top: 99px;
  width: 1093px;
}

.FutureClientSegmentation .vector-4 {
  height: 529px;
  left: 0;
  top: 98px;
  width: 1093px;
}

.FutureClientSegmentation .element-44 {
  height: 509px;
  left: 676px;
  top: 0;
  width: 1057px;
}

.FutureClientSegmentation .text-wrapper-19 {
  color: #33363b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 983px;
  letter-spacing: 0;
  line-height: normal;
  text-align: right;
  top: 542px;
}

.FutureClientSegmentation .text-wrapper-20 {
  color: #33363b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 31px;
  letter-spacing: 0;
  line-height: normal;
  text-align: right;
  top: 122px;
}

.FutureClientSegmentation .group-2 {
  height: 51px;
  left: 153px;
  top: 537px;
  width: 803px;
}

.FutureClientSegmentation .element-45 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 82px;
  letter-spacing: 0;
  line-height: normal;
  top: 17px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-46 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 189px;
  letter-spacing: 0;
  line-height: normal;
  top: 15px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-47 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 299px;
  letter-spacing: 0;
  line-height: normal;
  top: 15px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-48 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 409px;
  letter-spacing: 0;
  line-height: normal;
  top: 15px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-49 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 520px;
  letter-spacing: 0;
  line-height: normal;
  top: 15px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-50 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 630px;
  letter-spacing: 0;
  line-height: normal;
  top: 15px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-51 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 741px;
  letter-spacing: 0;
  line-height: normal;
  top: 16px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-52 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  top: 5px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .overlap-7 {
  height: 692px;
  left: 264px;
  top: 1223px;
  width: 1733px;
}

.FutureClientSegmentation .vector-5 {
  height: 593px;
  left: 0;
  top: 99px;
  width: 1093px;
}

.FutureClientSegmentation .vector-6 {
  height: 594px;
  left: 0;
  top: 98px;
  width: 1093px;
}

.FutureClientSegmentation .group-3 {
  height: 15px;
  left: 492px;
  top: 646px;
  width: 112px;
}

.FutureClientSegmentation .text-wrapper-21 {
  color: #33363b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 18px;
  letter-spacing: 0;
  line-height: normal;
  top: 0;
  white-space: nowrap;
}

.FutureClientSegmentation .element-53 {
  height: 14px;
  left: 0;
  top: 1px;
  width: 13px;
}

.FutureClientSegmentation .text-wrapper-22 {
  color: #33363b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 996px;
  letter-spacing: 0;
  line-height: normal;
  text-align: right;
  top: 549px;
}

.FutureClientSegmentation .text-wrapper-23 {
  color: #33363b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 31px;
  letter-spacing: 0;
  line-height: normal;
  top: 126px;
  white-space: nowrap;
}

.FutureClientSegmentation .text-wrapper-24 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 75px;
  letter-spacing: 0;
  line-height: normal;
  top: 565px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-25 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 341px;
  letter-spacing: 0;
  line-height: normal;
  top: 565px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-26 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 608px;
  letter-spacing: 0;
  line-height: normal;
  top: 565px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-27 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 880px;
  letter-spacing: 0;
  line-height: normal;
  top: 567px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-28 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 148px;
  letter-spacing: 0;
  line-height: normal;
  top: 562px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-29 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 406px;
  letter-spacing: 0;
  line-height: normal;
  top: 566px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-30 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 674px;
  letter-spacing: 0;
  line-height: normal;
  top: 566px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-31 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 207px;
  letter-spacing: 0;
  line-height: normal;
  top: 566px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-32 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 473px;
  letter-spacing: 0;
  line-height: normal;
  top: 566px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-33 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 743px;
  letter-spacing: 0;
  line-height: normal;
  top: 567px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-34 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 274px;
  letter-spacing: 0;
  line-height: normal;
  top: 566px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-35 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 539px;
  letter-spacing: 0;
  line-height: normal;
  top: 567px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .text-wrapper-36 {
  color: #474a4e;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 812px;
  letter-spacing: 0;
  line-height: normal;
  top: 567px;
  transform: rotate(-43.77deg);
}

.FutureClientSegmentation .element-54 {
  height: 173px;
  left: 131px;
  top: 365px;
  width: 21px;
}

.FutureClientSegmentation .element-55 {
  height: 173px;
  left: 397px;
  top: 365px;
  width: 21px;
}

.FutureClientSegmentation .element-56 {
  height: 173px;
  left: 664px;
  top: 365px;
  width: 21px;
}

.FutureClientSegmentation .element-57 {
  height: 336px;
  left: 198px;
  top: 202px;
  width: 21px;
}

.FutureClientSegmentation .element-58 {
  height: 336px;
  left: 463px;
  top: 202px;
  width: 21px;
}

.FutureClientSegmentation .element-59 {
  height: 336px;
  left: 732px;
  top: 202px;
  width: 21px;
}

.FutureClientSegmentation .element-60 {
  height: 342px;
  left: 264px;
  top: 196px;
  width: 21px;
}

.FutureClientSegmentation .element-61 {
  height: 342px;
  left: 529px;
  top: 196px;
  width: 21px;
}

.FutureClientSegmentation .element-62 {
  height: 342px;
  left: 801px;
  top: 196px;
  width: 21px;
}

.FutureClientSegmentation .element-63 {
  height: 342px;
  left: 939px;
  top: 196px;
  width: 21px;
}

.FutureClientSegmentation .element-64 {
  height: 266px;
  left: 330px;
  top: 272px;
  width: 21px;
}

.FutureClientSegmentation .element-65 {
  height: 266px;
  left: 596px;
  top: 272px;
  width: 21px;
}

.FutureClientSegmentation .element-66 {
  height: 266px;
  left: 871px;
  top: 272px;
  width: 21px;
}

.FutureClientSegmentation .navbar {
  height: 52px;
  left: 257px;
  top: 150px;
  width: 1179px;
}

.FutureClientSegmentation .overlap-group-2 {
  height: 4px;
  left: 0;
  top: 48px;
  width: 1169px;
}

.FutureClientSegmentation .element-67 {
  height: 1px;
  left: 0;
  object-fit: cover;
  top: 4px;
  width: 1169px;
}

.FutureClientSegmentation .element-68 {
  height: 4px;
  left: 21px;
  top: 0;
  width: 66px;
}

.FutureClientSegmentation .element-69 {
  height: 52px;
  left: 658px;
  top: -118px;
  width: 107px;
}

.FutureClientSegmentation .element-70 {
  height: 52px;
  left: 765px;
  top: -118px;
  width: 107px;
}

.FutureClientSegmentation .element-71 {
  height: 52px;
  left: 872px;
  top: -118px;
  width: 107px;
}

.FutureClientSegmentation .text-wrapper-37 {
  color: #7b828b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 147px;
  letter-spacing: 0;
  line-height: normal;
  top: 15px;
  white-space: nowrap;
}

.FutureClientSegmentation .text-wrapper-38 {
  color: #7b828b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 226px;
  letter-spacing: 0;
  line-height: normal;
  top: 15px;
  white-space: nowrap;
}

.FutureClientSegmentation .text-wrapper-39 {
  color: #7b828b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 357px;
  letter-spacing: 0;
  line-height: normal;
  top: 15px;
  white-space: nowrap;
}

.FutureClientSegmentation .text-wrapper-40 {
  color: #7b828b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 454px;
  letter-spacing: 0;
  line-height: normal;
  top: 15px;
  white-space: nowrap;
}

.FutureClientSegmentation .tips {
  color: #4046e3;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 40px;
  letter-spacing: 0;
  line-height: normal;
  top: 15px;
  white-space: nowrap;
}
