.CURegiModal {
  font-family: 'Pretendard-Bold', Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}
.CURegiModal .div {
  width: 664px;
  height: 628px;

}
.CURegiModal .div2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 624px;

  background: #f2f4f7;
  fill: #f2f4f7;
  border: 1px solid #d8dde3;
  border-radius: 10px;
  padding: 30px;
}

.CURegiModal .div3 {
  background: #ffffff;
  strokeWidth: 1px;
  height: 38px;
  width: 460px;
  border: 1px solid #d8dde3;
  border-radius: 5px;
  stroke: #d8dde3;
  display: flex;
  padding-left: 25px;
  align-items: center;
}
.CURegiModal .overlap-group-wrapper {
  height: 60px;
  left: 6px;
  top: 647px;
  width: 668px;
}

.CURegiModal .overlap-group-2 {
  background-size: 100% 100%;
  height: 60px;
  width: 664px;
}


.CURegiModal .text-wrapper-17 {
  color: #f9fafb;
  font-family: 'Pretendard-Medium', Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  top: 10px;
}

.CURegiModal .overlap-7 {
   background-size: 100% 100%;
  height: 36px;
  left: 450px;
  top: 12px;
  width: 72px;

}
.CURegiModal .overlap-6 {
   background-size: 100% 100%;
  height: 36px;
  left: 530px;
  top: 12px;
  width: 96px;

}

.CURegiModal .text-wrapper-18 {
  color: #474a4e;
  font-family: 'Pretendard-Medium', Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  top: 10px;
  width: 72px;
  height: 36px;
 
}

.CURegiModal .element-5 {
  height: 305px;
  left: 47px;
  object-fit: cover;
  top: 296px;
  width: 581px;
}

.CURegiModal .element-6 {
  height: 20px;
  left: -1224px;
  top: 1262px;
  width: 400px;
}
