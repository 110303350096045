
.sign-up .button-instance {
  margin: 136px;
  padding: 100px;
}

.sign-up {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 1192px;
  height: 1645px;
}

.sign-up .div {
  background-color: #ffffff;
  width: 480px;
}

.sign-up .rectangle {
  background-color: var(--greyscale-colorgrey-5);
  height: 1783px;

  width: 506px;
}

.sign-up .tittle {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-bold-pretendard-26pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-26pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-26pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-26pt-bold-font-weight);
  letter-spacing: var(--pretendard-bold-pretendard-26pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-26pt-bold-line-height);
  white-space: nowrap;
}

.sign-up .text-wrapper-2 {
  color: #474a4e;
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);

  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);

  white-space: nowrap;
}

.sign-up .p {
  color: var(--greyscale-colorgrey-6);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);

  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);

  white-space: nowrap;
}

.sign-up .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  gap: 2px;
}

.sign-up .text-wrapper-3 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sign-up .text-wrapper-4 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sign-up .div-wrapper {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-3);
  border-radius: 6px;
  display: flex;
  gap: 10px;

  padding: 9px 12px;

  width:100%;
}

.sign-up .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  gap: 2px;
}

.sign-up .frame-4 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-3);
  border-radius: 6px;
  display: flex;
  gap: 206px;

  padding: 9px 12px;

  width: 480px;
}

.sign-up .text-wrapper-5 {
  color: var(--greyscale-colorgrey-4);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
  margin-top: -1px;

  white-space: nowrap;
  width: fit-content;
}

.sign-up .text-wrapper-6 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: 12px;
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
}

.sign-up .text-wrapper-7 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: 10px;
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
}
.sign-up .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  gap: 2px;
}

.sign-up .ellipse {
  background-color: var(--greyscale-colorgrey-2);
  border-radius: 4px;
  height: 8px;

  width: 8px;
}

.sign-up .frame-6 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
}

.sign-up .rectangle-2 {
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-2);
  border-radius: 4px;
  height: 20px;

  width: 20px;
}

.sign-up .text-wrapper-7 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-bold-pretendard-14pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-14pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-14pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-14pt-bold-font-weight);
  letter-spacing: var(--pretendard-bold-pretendard-14pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-14pt-bold-line-height);
  margin-top: -1px;

  white-space: nowrap;
  width: fit-content;
}

.sign-up .overlap {
  background-color: var(--greyscale-colorgrey-0);
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  height: 44px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 480px;
  padding: 10px;
}

.sign-up .frame-7 {
  align-items: center;
  display: inline-flex;
  gap: 6px;
  height: 20px;

  width: 20px;
}

.sign-up .check-box-icon {
  height: 20px;

  width: 20px;
}

.sign-up .div-2 {
  color: transparent;
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sign-up .span {
  color: #e53073;
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
}

.sign-up .text-wrapper-8 {
  color: #474a4e;
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
}

.sign-up .plus-button-icon {
  height: 20px;

  width: 20px;
}

.sign-up .overlap-group {
  background-color: var(--greyscale-colorgrey-0);
  border-radius: 6px;
  height: 44px;
  padding: 10px;
  width: 480px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.sign-up .overlap-2 {
  background-color: var(--greyscale-colorgrey-0);
  border-radius: 6px;
  height: 82px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  display: grid;
  width: 480px;
}

.sign-up .frame-8 {
  align-items: center;
  display: inline-flex;
  gap: 6px;
}

.sign-up .frame-9 {
  align-items: center;
  display: inline-flex;
  gap: 6px;
}

.sign-up .text-wrapper-9 {
  color: #4046e3;
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
}

.sign-up .img {
  height: 20px;

  width: 20px;
}
.sign-up .overlap-3 {
  display: flex;
  align-items: center; 
  justify-content: center; 
  background-color: var(--greyscale-colorgrey-3);
  border-radius: 6px;
  height: 50px;
  width: 480px;
}
.sign-up .overlap-4 {
  display: flex;
  align-items: center; 
  justify-content: center; 
  background-color: var(--brand-colormainblue-0);
  border-radius: 6px;
  height: 50px;
  width: 480px;
}
.sign-up .text-wrapper-10 {
  color: var(--greyscale-colorgrey-0);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);

  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);

  white-space: nowrap;
}

.sign-up .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  gap: 2px;
}

.sign-up .frame-11 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-3);
  border-radius: 6px;
  display: flex;
  gap: 10px;

  padding: 9px 12px;
  height: 39px;
  width: 356px;
}

.sign-up .design-component-instance-node {
  padding: 9px 24px !important;
}

.sign-up .frame-12 {
  align-items: flex-start;
  display: inline-flex;
}

.sign-up .text-wrapper-11 {
  color: var(--greyscale-colorgrey-6);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);

  width: 421px;
}

.sign-up .frame-13 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-3);
  border-radius: 6px;
  display: flex;
  gap: 206px;
  padding: 9px 12px;
  height: 40px;
  width: 349px;
}

.sign-up .text-wrapper-12 {
  color: var(--greyscale-colorgrey-4);
  font-family: 'Pretendard-Medium', Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sign-up .frame-14 {
  align-items: flex-start;
  display: flex;
  gap: 2px;


}

.sign-up .text-wrapper-13 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
;



}

.sign-up .text-wrapper-14 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
  margin-right: -54px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sign-up .frame-15 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-3);
  border-radius: 6px;
  display: flex;
  gap: 206px;
  padding: 9px 12px;

 
}

.sign-up .frame-16 {

  gap: 2px;


}

.sign-up .text-wrapper-15 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
  margin-right: -6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sign-up .text-wrapper-16 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-14pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
  margin-right: -15px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sign-up .frame-17 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-3);
  border-radius: 6px;
  display: flex;
  gap: 206px;
  padding: 9px 12px;

  width: 232px;
}

.sign-up .button-2 {
  padding: 9px 24px;
}

.sign-up .text-wrapper-17 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);

  white-space: nowrap;
}

.sign-up .frame-wrapper {
  height: 40px;

  width: 376px;
}

.sign-up .frame-18 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-3);
  border-radius: 6px;
  display: flex;
justify-content: space-between;
  padding: 9px 12px;


}

.sign-up .text-wrapper-18 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);
  height: 22px;
  width: 70px;
  text-align: right;
}

.sign-up .button-3 {
  padding: 9px 24px !important;
}

.sign-up .text-wrapper-19 {
  color: var(--greyscale-colorgrey-6);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);

  white-space: nowrap;
}

.sign-up .text-wrapper-20 {
  color: var(--greyscale-colorgrey-6);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);

  white-space: nowrap;
}

.sign-up .ellipse-2 {
  background-color: var(--greyscale-colorgrey-2);
  border-radius: 4px;
  height: 8px;

  width: 8px;
}

.sign-up .ellipse-3 {
  background-color: var(--greyscale-colorgrey-2);
  border-radius: 4px;
  height: 8px;

  width: 8px;
}

.sign-up .text-wrapper-21 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
  height: 20px;
  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);

  white-space: nowrap;
}

.sign-up .text-wrapper-22 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
  height: 20px;
  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);

  white-space: nowrap;
}

.sign-up .text-wrapper-23 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
  height: 20px;
  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);

  white-space: nowrap;
}

.sign-up .text-wrapper-24 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
  height: 20px;
  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);

  white-space: nowrap;
}

.sign-up .text-wrapper-25 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
  height: 20px;
  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);

  white-space: nowrap;
}

.sign-up .text-wrapper-26 {
  color: var(--sub-colorred-0);
  font-family: var(--pretendard-medium-pretendard-12pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-12pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-12pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-12pt-medium-font-weight);
  height: 20px;
  letter-spacing: var(
    --pretendard-medium-pretendard-12pt-medium-letter-spacing
  );
  line-height: var(--pretendard-medium-pretendard-12pt-medium-line-height);

  white-space: nowrap;
}
