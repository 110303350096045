.BrandExpact {
  background-color: #f9fafb;


}

.BrandExpact .overlap-wrapper {

  height: 1605px;
  overflow: hidden;
  width: 1536px;
}

.BrandExpact .overlap {
  height: 1605px;

  width: 1542px;
}

.BrandExpact .overlap-group {
  height: 1605px;
  left: 0;

  top: 0;
  width: 1542px;
}

.BrandExpact .group {
  height: 60px;
  left: 0;

  top: 0;
  width: 1542px;
}

.BrandExpact .element {
  height: 60px;
  left: 0;

  top: 0;
  width: 1536px;
}

.BrandExpact .img {
  height: 1px;
  left: 240px;
  object-fit: cover;

  top: 60px;
  width: 1296px;
}

.BrandExpact .element-2 {
  height: 40px;
  left: 1305px;

  top: 10px;
  width: 207px;
}

.BrandExpact .element-3 {
  height: 28px;
  left: 1313px;

  top: 16px;
  width: 28px;
}

.BrandExpact .text-wrapper {
  color: #ffffff;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  left: 1322px;
  letter-spacing: 0;
  line-height: normal;

  top: 22px;
  white-space: nowrap;
}

.BrandExpact .div {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 1347px;
  letter-spacing: 0;
  line-height: normal;

  top: 21px;
}

.BrandExpact .text-wrapper-2 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;

  top: 17px;
  white-space: nowrap;
}

.BrandExpact .element-4 {
  height: 40px;
  left: -6643px;

  top: 2074px;
  width: 1248px;
}

.BrandExpact .element-5 {
  height: 1605px;
  left: 0;

  top: 0;
  width: 240px;
}

.BrandExpact .text-wrapper-3 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;

  top: 93px;
  white-space: nowrap;
}

.BrandExpact .group-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #bcc2c9;
  border-radius: 6px;
  height: 40px;
  left: 1014px;

  top: 85px;
  width: 135px;
}

.BrandExpact .group-2 {
  height: 17px;
  left: 18px;

  top: 11px;
  width: 100px;
}

.BrandExpact .text-wrapper-4 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;

  top: 0;
}

.BrandExpact .copy-icon {
  height: 16px;
  left: 0;

  top: 1px;
  width: 16px;
}

.BrandExpact .div-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d8dde3;
  border-radius: 6px;
  height: 56px;
  left: 264px;

  top: 174px;
  width: 885px;
}

.BrandExpact .text-wrapper-5 {
  color: #7b828b;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;

  top: 16px;
  white-space: nowrap;
}

.BrandExpact .text-wrapper-6 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;

  top: 269px;
  white-space: nowrap;
}

.BrandExpact .text-wrapper-7 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;

  top: 774px;
  white-space: nowrap;
}

.BrandExpact .text-wrapper-8 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;

  top: 448px;
  white-space: nowrap;
}

.BrandExpact .text-wrapper-9 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 264px;
  letter-spacing: 0;
  line-height: normal;

  top: 611px;
  white-space: nowrap;
}

.BrandExpact .navbar {
  border-radius: 6px;
  height: 88px;
  left: 264px;

  top: 312px;
  width: 885px;
}

.BrandExpact .rectangle {
  height: 44px;
  left: 0;

  top: 0;
  width: 885px;
}

.BrandExpact .rectangle-2 {
  height: 44px;
  left: 0;

  top: 44px;
  width: 885px;
}

.BrandExpact .arrow-drop-down-icon {
  height: 8px;
  left: 144px;

  top: 60px;
  width: 8px;
}

.BrandExpact .arrow-drop-down-icon-2 {
  height: 8px;
  left: 321px;

  top: 60px;
  width: 8px;
}

.BrandExpact .arrow-drop-down-icon-3 {
  height: 8px;
  left: 498px;

  top: 60px;
  width: 8px;
}

.BrandExpact .arrow-drop-down-icon-4 {
  height: 8px;
  left: 675px;

  top: 60px;
  width: 8px;
}

.BrandExpact .arrow-drop-down-icon-5 {
  height: 8px;
  left: 852px;

  top: 60px;
  width: 8px;
}

.BrandExpact .element-6 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 29px;
  letter-spacing: 0;
  line-height: normal;

  top: 54px;
}

.BrandExpact .element-7 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 206px;
  letter-spacing: 0;
  line-height: normal;

  top: 54px;
}

.BrandExpact .element-8 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 383px;
  letter-spacing: 0;
  line-height: normal;

  top: 54px;
}

.BrandExpact .element-9 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 560px;
  letter-spacing: 0;
  line-height: normal;

  top: 54px;
}

.BrandExpact .element-10 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 737px;
  letter-spacing: 0;
  line-height: normal;

  top: 54px;
}

.BrandExpact .element-11 {
  height: 88px;
  left: 176px;

  top: 0;
  width: 1px;
}

.BrandExpact .element-12 {
  height: 88px;
  left: 354px;

  top: 0;
  width: 1px;
}

.BrandExpact .element-13 {
  height: 88px;
  left: 530px;

  top: 0;
  width: 1px;
}

.BrandExpact .element-14 {
  height: 88px;
  left: 708px;

  top: 0;
  width: 1px;
}

.BrandExpact .text-wrapper-10 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;

  top: 12px;
}

.BrandExpact .text-wrapper-11 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 197px;
  letter-spacing: 0;
  line-height: normal;

  top: 12px;
}

.BrandExpact .text-wrapper-12 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 374px;
  letter-spacing: 0;
  line-height: normal;

  top: 12px;
}

.BrandExpact .text-wrapper-13 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 551px;
  letter-spacing: 0;
  line-height: normal;

  top: 12px;
}

.BrandExpact .text-wrapper-14 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 728px;
  letter-spacing: 0;
  line-height: normal;

  top: 12px;
}

.BrandExpact .rectangle-3 {
  border: 1px solid;
  border-color: #d8dde3;
  border-radius: 6px;
  height: 88px;
  left: 0;

  top: 0;
  width: 885px;
}

.BrandExpact .p {
  color: #64686e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 805px;
  letter-spacing: 0;
  line-height: normal;

  top: 578px;
}

.BrandExpact .overlap-2 {

  background-size: 100% 100%;
  height: 72px;
  left: 264px;

  top: 491px;
  width: 885px;
}

.BrandExpact .element-15 {
  height: 72px;
  left: 443px;

  top: 0;
  width: 1px;
}

.BrandExpact .element-16 {
  color: #474a4e;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 373px;
  letter-spacing: 0;
  line-height: normal;

  text-align: right;
  top: 20px;
}

.BrandExpact .element-17 {
  color: #474a4e;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 825px;
  letter-spacing: 0;
  line-height: normal;

  text-align: right;
  top: 20px;
}

.BrandExpact .text-wrapper-15 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 19px;
  letter-spacing: 0;
  line-height: normal;

  top: 26px;
}

.BrandExpact .text-wrapper-16 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 458px;
  letter-spacing: 0;
  line-height: normal;

  top: 26px;
}

.BrandExpact .overlap-3 {

  background-size: 100% 100%;
  height: 72px;
  left: 264px;

  top: 654px;
  width: 885px;
}

.BrandExpact .element-18 {
  color: #474a4e;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 727px;
  letter-spacing: 0;
  line-height: normal;

  text-align: right;
  top: 21px;
}

.BrandExpact .overlap-4 {
  height: 564px;
  left: 264px;

  top: 817px;
  width: 886px;
}

.BrandExpact .element-19 {
  height: 555px;
  left: 0;

  top: 0;
  width: 885px;
}

.BrandExpact .element-20 {
  height: 48px;
  left: 0;

  top: 0;
  width: 885px;
}

.BrandExpact .element-21 {
  height: 48px;
  left: 0;

  top: 516px;
  width: 885px;
}

.BrandExpact .element-22 {
  height: 468px;
  left: 221px;

  top: 48px;
  width: 1px;
}

.BrandExpact .element-23 {
  height: 468px;
  left: 443px;

  top: 48px;
  width: 1px;
}

.BrandExpact .element-24 {
  height: 468px;
  left: 664px;

  top: 48px;
  width: 1px;
}

.BrandExpact .element-25 {
  height: 514px;
  left: 885px;

  top: 41px;
  width: 1px;
}

.BrandExpact .element-26 {
  height: 20px;
  left: 221px;

  top: 15px;
  width: 1px;
}

.BrandExpact .element-27 {
  height: 20px;
  left: 221px;

  top: 530px;
  width: 1px;
}

.BrandExpact .element-28 {
  height: 20px;
  left: 443px;

  top: 15px;
  width: 1px;
}

.BrandExpact .element-29 {
  height: 20px;
  left: 443px;

  top: 530px;
  width: 1px;
}

.BrandExpact .element-30 {
  height: 20px;
  left: 664px;

  top: 15px;
  width: 1px;
}

.BrandExpact .element-31 {
  height: 20px;
  left: 664px;

  top: 530px;
  width: 1px;
}

.BrandExpact .element-32 {
  height: 564px;
  left: 0;

  top: 0;
  width: 885px;
}

.BrandExpact .text-wrapper-17 {
  color: #64686e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 14px;
  width: 36px;
}

.BrandExpact .text-wrapper-18 {
  color: #64686e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 14px;
}

.BrandExpact .text-wrapper-19 {
  color: #64686e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 467px;
  letter-spacing: 0;
  line-height: normal;

  top: 14px;
}

.BrandExpact .text-wrapper-20 {
  color: #64686e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 688px;
  letter-spacing: 0;
  line-height: normal;

  top: 14px;
}

.BrandExpact .element-33 {
  height: 1px;
  left: 0;

  top: 87px;
  width: 885px;
}

.BrandExpact .element-34 {
  height: 1px;
  left: 0;

  top: 126px;
  width: 885px;
}

.BrandExpact .element-35 {
  height: 1px;
  left: 0;

  top: 165px;
  width: 885px;
}

.BrandExpact .element-36 {
  height: 1px;
  left: 0;

  top: 204px;
  width: 885px;
}

.BrandExpact .element-37 {
  height: 1px;
  left: 0;

  top: 243px;
  width: 885px;
}

.BrandExpact .element-38 {
  height: 1px;
  left: 0;

  top: 360px;
  width: 885px;
}

.BrandExpact .element-39 {
  height: 1px;
  left: 0;

  top: 477px;
  width: 885px;
}

.BrandExpact .element-40 {
  height: 1px;
  left: 0;

  top: 282px;
  width: 885px;
}

.BrandExpact .element-41 {
  height: 1px;
  left: 0;

  top: 399px;
  width: 885px;
}

.BrandExpact .element-42 {
  height: 1px;
  left: 0;

  top: 516px;
  width: 885px;
}

.BrandExpact .element-43 {
  height: 1px;
  left: 0;

  top: 321px;
  width: 885px;
}

.BrandExpact .element-44 {
  height: 1px;
  left: 0;

  top: 438px;
  width: 885px;
}

.BrandExpact .a {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 58px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-2 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 97px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-3 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 136px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-4 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 175px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-5 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 214px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-6 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 331px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-7 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 448px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-8 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 253px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-9 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 370px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-10 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 487px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-11 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 292px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-12 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 409px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-13 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;

  top: 531px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-14 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 58px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-15 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 97px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-16 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 136px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-17 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 175px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-18 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 214px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-19 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 331px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-20 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 448px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-21 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 253px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-22 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 370px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-23 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 487px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-24 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 292px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-25 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 409px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-26 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 469px;
  letter-spacing: 0;
  line-height: normal;

  top: 531px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-27 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 58px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-28 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 97px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-29 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 136px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-30 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 175px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-31 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 214px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-32 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 331px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-33 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 448px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-34 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 253px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-35 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 370px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-36 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 487px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-37 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 292px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-38 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 409px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-39 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 246px;
  letter-spacing: 0;
  line-height: normal;

  top: 531px;
  white-space: nowrap;
  width: 133px;
}

.BrandExpact .a-40 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 58px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-41 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 97px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-42 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 136px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-43 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 175px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-44 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 214px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-45 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 331px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-46 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 448px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-47 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 253px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-48 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 370px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-49 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 487px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-50 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 292px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-51 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 409px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .a-52 {
  color: #474a4e;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;

  top: 531px;
  white-space: nowrap;
  width: 51px;
}

.BrandExpact .overlap-group-wrapper {
  height: 127px;
  left: 1180px;

  top: 334px;
  width: 176px;
}

.BrandExpact .overlap-5 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d8dde3;
  border-radius: 6px;
  box-shadow: 0px 2px 6px #33363b29;
  height: 127px;

  width: 170px;
}

.BrandExpact .text-wrapper-21 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 14px;
  letter-spacing: 0;
  line-height: normal;

  top: 54px;
}

.BrandExpact .text-wrapper-22 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 14px;
  letter-spacing: 0;
  line-height: normal;

  top: 16px;
}

.BrandExpact .text-wrapper-23 {
  color: #474a4e;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 14px;
  letter-spacing: 0;
  line-height: normal;

  top: 92px;
}
