.SSRegiModal {
  font-family: 'Pretendard-Bold', Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}
.SSRegiModal .div {
  width: 664px;
  height: 703px;


}
.SSRegiModal .div2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 624px;
  height: 160px;
  background: #f2f4f7;
  fill: #f2f4f7;
  border: 1px solid #d8dde3;
  border-radius: 10px;
  padding: 30px;
}

.SSRegiModal .div3 {
  background: #ffffff;
  strokeWidth: 1px;
  height: 38px;
  width: 460px;
  border: 1px solid #d8dde3;
  border-radius: 5px;
  stroke: #d8dde3;
  display: flex;
  padding-left: 25px;
  align-items: center;
}
.SSRegiModal .overlap-group-wrapper {
  height: 60px;
  left: 6px;
  top: 647px;
  width: 668px;
}

.SSRegiModal .overlap-group-2 {
  background-size: 100% 100%;
  height: 60px;
  width: 664px;
}


.SSRegiModal .overlap-7 {
   background-size: 100% 100%;
  height: 36px;
  left: 450px;
  top: 12px;
  width: 72px;

}
.SSRegiModal .overlap-6 {
  background-size: 100% 100%;
  height: 36px;
  left: 530px;
  top: 12px;
  width: 96px;

}

.SSRegiModal .text-wrapper-18 {
  color: #474a4e;
  font-family: 'Pretendard-Medium', Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  top: 10px;
  width: 72px;
  height: 36px;
 
}

.SSRegiModal .element-5 {
  height: 305px;
  left: 47px;
  object-fit: cover;
  top: 296px;
  width: 581px;
}

.SSRegiModal .element-6 {
  height: 20px;
  left: -1224px;
  top: 1262px;
  width: 400px;
}
