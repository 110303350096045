:root {
  --16: 0px 2px 6px 0px rgba(51, 54, 59, 0.2);
  --brand-colormainblack-0: rgba(51, 54, 59, 1);
  --brand-colormainblue-0: rgba(64, 70, 227, 1);
  --brand-colormainblue-l1: rgba(221, 223, 248, 1);
  --greyscale-colorgrey-0: rgba(249, 250, 251, 1);
  --greyscale-colorgrey-1: rgba(234, 236, 239, 1);
  --greyscale-colorgrey-2: rgba(216, 221, 227, 1);
  --greyscale-colorgrey-3: rgba(188, 194, 201, 1);
  --greyscale-colorgrey-4: rgba(161, 167, 177, 1);
  --greyscale-colorgrey-5: rgba(123, 130, 139, 1);
  --greyscale-colorgrey-6: rgba(100, 104, 110, 1);
  --greyscale-colorgrey-7: rgba(71, 74, 78, 1);
  --pretendard-bold-pretendard-14pt-bold-font-family: "Pretendard", Helvetica;
  --pretendard-bold-pretendard-14pt-bold-font-size: 14px;
  --pretendard-bold-pretendard-14pt-bold-font-style: normal;
  --pretendard-bold-pretendard-14pt-bold-font-weight: 700;
  --pretendard-bold-pretendard-14pt-bold-letter-spacing: -0.23800000667572022px;
  --pretendard-bold-pretendard-14pt-bold-line-height: 22px;
  --pretendard-bold-pretendard-16pt-bold-font-family: "Pretendard", Helvetica;
  --pretendard-bold-pretendard-16pt-bold-font-size: 16px;
  --pretendard-bold-pretendard-16pt-bold-font-style: normal;
  --pretendard-bold-pretendard-16pt-bold-font-weight: 700;
  --pretendard-bold-pretendard-16pt-bold-letter-spacing: -0.2720000076293945px;
  --pretendard-bold-pretendard-16pt-bold-line-height: 26px;
  --pretendard-bold-pretendard-20pt-bold-font-family: "Pretendard", Helvetica;
  --pretendard-bold-pretendard-20pt-bold-font-size: 20px;
  --pretendard-bold-pretendard-20pt-bold-font-style: normal;
  --pretendard-bold-pretendard-20pt-bold-font-weight: 700;
  --pretendard-bold-pretendard-20pt-bold-letter-spacing: -0.34000000953674314px;
  --pretendard-bold-pretendard-20pt-bold-line-height: 32px;
  --pretendard-bold-pretendard-26pt-bold-font-family: "Pretendard", Helvetica;
  --pretendard-bold-pretendard-26pt-bold-font-size: 26px;
  --pretendard-bold-pretendard-26pt-bold-font-style: normal;
  --pretendard-bold-pretendard-26pt-bold-font-weight: 700;
  --pretendard-bold-pretendard-26pt-bold-letter-spacing: -0.4420000123977661px;
  --pretendard-bold-pretendard-26pt-bold-line-height: 42px;
  --pretendard-medium-pretendard-12pt-medium-font-family: "Pretendard", Helvetica;
  --pretendard-medium-pretendard-12pt-medium-font-size: 12px;
  --pretendard-medium-pretendard-12pt-medium-font-style: normal;
  --pretendard-medium-pretendard-12pt-medium-font-weight: 500;
  --pretendard-medium-pretendard-12pt-medium-letter-spacing: -0.2040000057220459px;
  --pretendard-medium-pretendard-12pt-medium-line-height: 20px;
  --pretendard-medium-pretendard-14pt-medium-font-family: "Pretendard", Helvetica;
  --pretendard-medium-pretendard-14pt-medium-font-size: 14px;
  --pretendard-medium-pretendard-14pt-medium-font-style: normal;
  --pretendard-medium-pretendard-14pt-medium-font-weight: 500;
  --pretendard-medium-pretendard-14pt-medium-letter-spacing: -0.23800000667572022px;
  --pretendard-medium-pretendard-14pt-medium-line-height: 22px;
  --sub-colorgreen-d1: rgba(77, 185, 114, 1);
  --sub-colorred-0: rgba(229, 48, 115, 1);
}
