.button {
    all: unset;
    align-items: center;
    border-radius: 6px;
    box-sizing: border-box;
    display: inline-flex;
    gap: 4px;
    justify-content: center;
    position: relative;
  }
  
  .button .instance-node {
    height: 16px !important;
    position: relative !important;
    width: 16px !important;
  }
  
  .button .text {
    font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
    font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
    font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
    font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
    letter-spacing: var(--pretendard-medium-pretendard-14pt-medium-letter-spacing);
    line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .button.icon {
    background-color: #4046e3;
    padding: 9px 24px;
  }
  
  .button.default {
    background-color: var(--brand-colormainblue-0);
    padding: 9px 28px 9px 24px;
  }
  
  .button.variant-3 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #4046e3;
    padding: 9px 24px;
  }
  
  .button.variant-4 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #4046e3;
    height: 40px;
    padding: 9px 28px 9px 24px;
  }
  
  .button.icon .text {
    color: #ffffff;
  }
  
  .button.default .text {
    color: #ffffff;
  }
  
  .button.variant-3 .text {
    color: #4046e3;
  }
  
  .button.variant-4 .text {
    color: #4046e3;
  }
  