.root {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis; 
  background-color: #F9FAFB;
}


:focus {
  outline: none;
}
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;