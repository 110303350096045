

.findmyid {
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  width: 100%;
}

.findmyid .div {
  background-color: var(--greyscale-colorgrey-0);
  height: 100vh;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  width: 1688px;
}

.findmyid .overlap-group {
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: var(--16);
  width: 740px;
  height: 574px;

  padding:  60px 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 
  .login .overlap-group {
    background-color: #ffffff;
    border: 1px solid;
    border-color: var(--greyscale-colorgrey-2);
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    align-items: left; 
    justify-content: space-between;
    height: 540px;
    width: 660px;
  } */

.findmyid .overlap {
  background-color: var(--greyscale-colorgrey-3);
  border-radius: 6px;
  height: 50px;
  left: 90px;
  display: flex;
  justify-content: center;
  justify-items: center;
  top: 427px;
  width: 480px;
}

.findmyid .disbutton {
  background-color: var(--brand-colormainblue-l1);
  border-radius: 6px;
  height: 50px;
  left: 90px;
  display: flex;
  justify-content: center;
  justify-items: center;
  top: 427px;
  width: 480px;
}

.findmyid .button {
  background-color: var(--brand-colormainblue-0);
  border-radius: 6px;
  height: 50px;
  left: 90px;
  display: flex;
  justify-content: center;
  justify-items: center;
  top: 427px;
  width: 480px;
}

.findmyid .overlap-2 {
  background-color: var(--brand-colormainblue-0);
  border-radius: 6px;

  left: 90px;
  display: flex;
  justify-content: center;
  justify-items: center;
  top: 427px;

}
.findmyid .text-subtittle {
  font-weight: var(--pretendard-bold-pretendard-14pt-bold-font-weight) !important;
  border-top: #000000;
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(--pretendard-medium-pretendard-14pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; 
  height: 100%;
}
.findmyid .text-wrapper-2 {
  color: var(--greyscale-colorgrey-0);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(--pretendard-medium-pretendard-14pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
  display: flex;
  align-items: center;
  height: 50px;

}


.findmyid .check-box-icon {
  height: 20px;
  left: 89px;

  top: 359px;
  width: 20px;
}

.findmyid .text-wrapper-3 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  left: 115px;
  letter-spacing: var(--pretendard-medium-pretendard-14pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);

  top: 357px;
  white-space: nowrap;
}

.findmyid .text-wrapper-4 {
  color: var(--greyscale-colorgrey-6);
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 454px;
  letter-spacing: -0.24px;
  line-height: 22px;

  text-decoration: underline;
  top: 357px;
  white-space: nowrap;
}

.findmyid .div-wrapper {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-3);
  border-radius: 6px;
  display: flex;
  gap: 206px;
  height: 44px;
  left: 89px;
  padding: 9px 12px;

  top: 168px;

}

.findmyid .text-wrapper-5 {
  color: #000000;
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(--pretendard-medium-pretendard-14pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);

  white-space: nowrap;
}

.findmyid .frame-2 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--greyscale-colorgrey-3);
  border-radius: 6px;
  display: flex;
  gap: 206px;
  height: 44px;
  left: 89px;
  padding: 9px 12px;

  top: 276px;
  width: 480px;
}

.findmyid .text-wrapper-6 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-bold-pretendard-26pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-26pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-26pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-26pt-bold-font-weight);

  letter-spacing: var(--pretendard-bold-pretendard-26pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-26pt-bold-line-height);


  white-space: nowrap;
}

.findmyid .frame-3 {
  align-items: center;
  display: inline-flex;
  gap: 7px;
  left: 89px;

  top: 140px;
}

.findmyid .text-wrapper-7 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-bold-pretendard-14pt-bold-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-bold-pretendard-14pt-bold-font-family);
  letter-spacing: var(--pretendard-medium-pretendard-14pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
  margin-top: -1px;

  white-space: nowrap;
  width: fit-content;
}

.findmyid .text-wrapper-9{
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(--pretendard-medium-pretendard-14pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);
  margin-top: -1px;

  white-space: nowrap;
  width: fit-content;
}
.element .frame-4 {
  align-items: center;
  background-color: var(--brand-colormainblack-0);
  border-radius: 6px;
  display: flex;
  gap: 12px;
  left: 444px;
  overflow: hidden;
  padding: 13px 20px;

  top: 776px;
  width: 800px;
}
.findmyid .frame-4 {
  align-items: center;
  display: inline-flex;
  gap: 4px;
  left: 89px;

  top: 248px;
}

.findmyid .as {
  background-color: #000000c5;
  border-radius: 10px; 
  display: flex;
  height: 48px;
  width: 800px;
  padding-left: 20px;
  gap: 10px;
  align-items: center; 
}
.findmyid .frame-5 {
  align-items: center;
  display: inline-flex;
  gap: 4px;
  left: 687px;

  top: 741px;
}

.findmyid .img {
  height: 48px;
  left: 751px;

  top: 80px;
  width: 185px;
}

.findmyid .button-instance {
  background-color: var(--brand-colormainblue-l1) !important;
  color: var(--brand-colormainblue-0) !important;
}

.findmyid .design-component-instance-node {
  color: var(--brand-colormainblue-0) !important;
  font-family: var(--pretendard-bold-pretendard-14pt-bold-font-family) !important;
  font-size: var(--pretendard-bold-pretendard-14pt-bold-font-size) !important;
  font-style: var(--pretendard-bold-pretendard-14pt-bold-font-style) !important;
  font-weight: var(--pretendard-bold-pretendard-14pt-bold-font-weight) !important;

  letter-spacing: var(--pretendard-bold-pretendard-14pt-bold-letter-spacing) !important;
  line-height: var(--pretendard-bold-pretendard-14pt-bold-line-height) !important;
}

.findmyid .text {
  color: var(--brand-colormainblue-0) !important;
  font-family: var(--pretendard-bold-pretendard-14pt-bold-font-family) !important;
  font-size: var(--pretendard-bold-pretendard-14pt-bold-font-size) !important;
  font-style: var(--pretendard-bold-pretendard-14pt-bold-font-style) !important;
  font-weight: var(--pretendard-bold-pretendard-14pt-bold-font-weight) !important;

  letter-spacing: var(--pretendard-bold-pretendard-14pt-bold-letter-spacing) !important;
  line-height: var(--pretendard-bold-pretendard-14pt-bold-line-height) !important;
}
.findmyid .text2 {
  color: wheat;
  font-family: var(--pretendard-bold-pretendard-14pt-bold-font-family) !important;
  font-size: var(--pretendard-bold-pretendard-14pt-bold-font-size) !important;
  font-style: var(--pretendard-bold-pretendard-14pt-bold-font-style) !important;
  font-weight: var(--pretendard-bold-pretendard-14pt-bold-font-weight) !important;

  letter-spacing: var(--pretendard-bold-pretendard-14pt-bold-letter-spacing) !important;
  line-height: var(--pretendard-bold-pretendard-14pt-bold-line-height) !important;
}
.findmyid .group {
  height: 204px;
  left: 544px;
  top: 354px;
  width: 602px;
}


.findmyid .text-wrapper-8 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-bold-pretendard-16pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-16pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-16pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-16pt-bold-font-weight);
  letter-spacing: var(--pretendard-bold-pretendard-16pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-16pt-bold-line-height);

  white-space: nowrap;
}

.findmyid .vector {
  height: .5px;
  left: 20px;
  object-fit: cover;
  top: 60px;
  width: 560px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #d3d3d3;
}

.findmyid .frame-8 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  top: 80px;
}

.findmyid .text-wrapper-9 {
  color: var(--greyscale-colorgrey-7);
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  letter-spacing: var(--pretendard-medium-pretendard-14pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);

}

.findmyid .frame-wrapper {
  background-color: var(--greyscale-colorgrey-0);
  width: 100%;
  display: flex;
  justify-content: end;
  border-radius: 6px;
}

.findmyid .button-wrapper {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  left: 421px;
  top: 12px;
}

.findmyid .button-2 {
  flex: 0 0 auto !important;
  color: #ffffff;
  justify-content: flex-end !important;
  padding: 7px 24px !important;
}

.screen {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.screen .div {
  background-color: #ffffff;
  height: 728px;

  width: 640px;
}

.screen .overlap {
  background-size: 100% 100%;
  height: 202px;
  left: 0;

  top: 0;
  width: 640px;
}

.screen .img {
  height: 46px;
  left: 40px;

  top: 40px;
  width: 178px;
}

.screen .text-wrapper-2 {
  color: var(--greyscale-colorgrey-0);
  font-family: var(--pretendard-bold-pretendard-20pt-bold-font-family);
  font-size: var(--pretendard-bold-pretendard-20pt-bold-font-size);
  font-style: var(--pretendard-bold-pretendard-20pt-bold-font-style);
  font-weight: var(--pretendard-bold-pretendard-20pt-bold-font-weight);
  left: 40px;
  letter-spacing: var(--pretendard-bold-pretendard-20pt-bold-letter-spacing);
  line-height: var(--pretendard-bold-pretendard-20pt-bold-line-height);

  top: 89px;
  white-space: nowrap;
}

.screen .p {
  color: #000000;
  font-family: var(--pretendard-medium-pretendard-14pt-medium-font-family);
  font-size: var(--pretendard-medium-pretendard-14pt-medium-font-size);
  font-style: var(--pretendard-medium-pretendard-14pt-medium-font-style);
  font-weight: var(--pretendard-medium-pretendard-14pt-medium-font-weight);
  left: 40px;
  letter-spacing: var(--pretendard-medium-pretendard-14pt-medium-letter-spacing);
  line-height: var(--pretendard-medium-pretendard-14pt-medium-line-height);

  top: 241px;
  white-space: nowrap;
}

.screen .button-instance {
  left: 41px !important;
  padding: 13px 24px !important;

  top: 304px !important;
}

.screen .overlap-group {
  background-color: var(--greyscale-colorgrey-1);
  height: 120px;
  left: 0;

  top: 608px;
  width: 640px;
}

.screen .text-wrapper-3 {
  color: var(--greyscale-colorgrey-7);
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 40px;
  letter-spacing: 0;
  line-height: normal;

  top: 19px;
  white-space: nowrap;
}

.screen .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 6px;
  left: 40px;
 
  top: 46px;
}

.screen .text-wrapper-4 {
  color: var(--greyscale-colorgrey-6);
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;

  white-space: nowrap;
  width: fit-content;
}

.screen .text-wrapper-5 {
  color: var(--greyscale-colorgrey-6);
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
  width: fit-content;
}
