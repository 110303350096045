.cvcvtab {
  background-color: #33363B;
  display: flex;
  flex-direction: column;

  min-width: 240px;


  gap: 8px;
}
.cvcvtab .headless-wrapper {
  height: 30px;
  display: flex;
  align-items: center; 
  justify-content: left; 
  padding-left: 30px;
  padding-top: 30px;
  justify-items: center;
}

.cvcvtab .headless-logo {
  height: 24px;
  width: 140px;
}

.cvcvtab .tab-item {
  height: 27px;
  margin-left: 18px;
  margin-right: 18px;
  gap: 11px;
  display: flex;
  align-items: center;

}

.cvcvtab .tab-item-selected {
  height: 27px;
  margin-left: 15px;
  margin-right: 18px;
  border-left-color: #545ade;
  border-left-width: 3px;
  gap: 11px;
  justify-items: center;
  background: #474A4E;
  display: flex;
}
.cvcvtab .icon {
  height: 18px;
  width: 18px;
}
.cvcvtab .sec-text {
  color: #BCC2C9;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  white-space: nowrap;
  align-items: center; 

}
.cvcvtab .text {
  color: #F9FAFB;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  white-space: nowrap;
  align-items: center; 
}
.cvcvtab .line-box {
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center; 
  justify-content: left; 
  justify-items: center;

}
.cvcvtab .line {
  height: 1px;
  width: 100%;
  background-color: #474A4E;
}
.cvcvtab .overlap {
  background-color: var(--greyscale-colorgrey-7);
  border-radius: 0px 6px 6px 0px;
  height: 28px;
  left: 12px;
  position: absolute;
  top: 271px;
  width: 216px;
}

.cvcvtab .rectangle {
  background-color: #545ade;
  border-radius: 4px;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}

.cvcvtab .text-wrapper {
  color: var(--greyscale-colorgrey-0);
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  left: 48px;
  letter-spacing: -0.24px;
  line-height: 22px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}