::-webkit-scrollbar {
  width: 0;
  height: 0px;
  margin-right: -10px; 
  padding-right: 10px; 
}

::-webkit-scrollbar-track {
  background-color: #f1f1f100;
}

::-webkit-scrollbar-thumb {
  background: #00000000; 
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00000077; 
}

